.detalles-desglose__card {
    background-color: var(--color-card);
    border-left: 1px solid var(--color-background-elements);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.detalles-desglose__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    padding: var(--padding-lg);
    min-height: 150px;
    border-bottom: 1px solid var(--color-background-elements);
    flex: 0 0 auto;
}

.detalles-desglose__header-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detalles-desglose__header-detalle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}

.detalles-desglose__body {
    padding: var(--padding-lg);
    flex: 1;
    overflow-y: auto;
}

.detalles-desglose__no-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 100%;
}

.detalles-desglose__no-content-titulo {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--color-text-light);
}

.detalles-desglose__no-content-texto {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
    color: var(--color-text-light);
}

.detalles-desglose__conceptos {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.detalles-desglose__concepto {
    width: 100%;
}

.detalles-desglose__concepto-box {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
}

.detalles-desglose__concepto-imagen {
    width: 100px;
    height: 100px;
    border-radius: var(--border-radius-md);
    background-color: var(--color-background-elements);
    flex: 0 0 auto;
}

.detalles-desglose__concepto-info {
    width: 100%;
    height: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 5px;
    flex: 1;
}

.detalles-desglose__concepto-titulo {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detalles-desglose__concepto-close {
    width: 18px;
    height: 18px;
    font-size: var(--font-size-2xs);
    cursor: pointer;
    display: grid;
    place-content: center;
    border-radius: var(--border-radius-circle);
    background-color: var(--color-negativo-text);
    color: var(--color-negativo);
    padding: var(--padding-sm);
}

.detalles-desglose__concepto-detalle {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.detalles-desglose__concepto-precio {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
    color: var(--color-text-light);
}

.detalles-desglose__concepto-total {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.detalles-desglose__concepto-cantidad {
    display: flex;
    gap: 10px;
    align-items: center;
}

.detalles-desglose__concepto-cantidad__menos,
.detalles-desglose__concepto-cantidad__mas {
    width: 18px;
    height: 18px;
    font-size: var(--font-size-2xs);
    cursor: pointer;
    display: grid;
    place-content: center;
    border-radius: var(--border-radius-circle);
    border: 1px solid;
    border-color: var(--color-text-resaltado);
    color: var(--color-text-resaltado)
}

.detalles-desglose__concepto-subtotal {

    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
}

.detalle-desglose__footer {
    display: flex;
    flex-direction: column;
    padding: var(--padding-lg);
    border-top: 1px solid var(--color-background-elements);
    flex: 0 0 auto;
    gap: 10px;
}

.detalle-desglose__footer-total {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    background-color: var(--color-background-elements);
    padding: var(--padding-md);
    border-radius: var(--border-radius-md);
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
}

.detalle-desglose__footer-total span {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
}