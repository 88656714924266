.venta-ticket {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
}

.venta-ticket__header {
    width: 100%;
    padding: var(--padding-lg);
    border-bottom: 1px solid var(--color-border);
}

.venta-ticket__estatus {
    width: 100%;
    padding: var(--padding-md);
    border-radius: var(--border-radius-md);
    display: flex;
    background-color: var(--color-activo);
}

.venta-ticket__estatus-icono {
    width: 60px;
    height: 60px;
    display: grid;
    place-content: center;
    padding: var(--padding-sm);
    color: var(--color-activo-text);
    font-size: calc(var(--font-size-2xl) + 10px);
}

.venta-ticket__estatus-info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: var(--padding-sm) var(--padding-lg);
}

.venta-ticket__estatus-titulo {
    width: 100%;
    color: var(--color-text);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
}

.venta-ticket__estatus-text {
    width: 100%;
    color: var(--color-text-light);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
}

.venta-ticket__body {
    width: 100%;
    padding: var(--padding-xl);
}

.ticket-recibo {
    width: 100%;
    min-height: 400px;
    padding: var(--padding-md);
    border: 2px dashed var(--color-border);
    border-radius: var(--border-radius-md);
}

.ticket-recibo__header {
    width: 100%;
    padding: var(--padding-sm);
    margin-bottom: var(--padding-md);
}

.ticket-recibo__logo {
    min-height: 40px;
}

.ticket-recibo__info {
    width: 100%;
    padding: var(--padding-md) var(--padding-sm);
    display: flex;
    gap: 5px;
    flex-direction: column;
    border-bottom: 1px solid var(--color-border);
}

.ticket-recibo__info-detalle {
    width: 100%;
    display: flex;
    gap: 2px;
    color: var(--color-text);
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-sm);
}