
.card-informacion {
    width: 100%;
    height: fit-content;
    padding: var(--padding-md);
    border-radius: var(--border-radius-md);
    box-shadow: var(--box-shadow-dark);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    background: var(--color-card);
}

.card-informacion__detalles {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card-informacion__detalles__titulo {
    width: 100%;
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-medium);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-informacion__detalles__extra {
    width: 100%;
    font-size: var(--font-size-md);
    color: var(--color-text-light);
    font-weight: var(--font-weight-medium);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.card-informacion__detalles__extra span {
    color: initial;
}