.receta-print {
    width: 100%;
}

.receta-print__card {
    width: 100%;
    height: 100%;
    background-color: var(--color-card);
    border-radius: var(--border-radius-md);
}

.receta-print__header {
    width: 100%;
    border-bottom: 2px solid var(--color-background-elements-hover);
    padding: var(--padding-md);
    display: flex;
    align-items: center;
}

.receta-print__header-logo {
    width: 100px;
    height: 100px;
    flex: 0 0 auto;
    /* border-radius: 50%;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--margin-md); */
}

.receta-print__header-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--padding-sm);
    flex: 1;
    gap: 10px;
}

.receta-print__header-titulo {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
}

.receta-print__header-subtitulo {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
}

.receta-print__header-detalle {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: var(--font-size-sm);
    color: var(--color-text-light);
}

.receta-print__body {
    padding: var(--padding-md);
    width: 100%;
}

.receta-print__paciente {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding-sm);
}

.receta-print__paciente-info {
    display: flex;
    gap: 5px;
    margin-bottom: var(--padding-lg);
}

.receta-print__paciente-info__titulo {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
}

.receta-print__paciente-info__detalle {
    font-size: var(--font-size-md);
    color: var(--color-text-light);
}

.receta-print__diagnostico {
    padding: var(--padding-sm);
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: var(--padding-xl);
}

.receta-print__diagnostico-titulo {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
}

.receta-print__diagnostico-detalle {
    font-size: var(--font-size-md);
    color: var(--color-text-light);
}

.recetas-print__conceptos {
    padding: var(--padding-sm);
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: var(--padding-xl);
}

.receta-print__conceptos-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding-sm);
}

.recetas-print__conceptos-titulo {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
}

.recetas-print__conceptos-total {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
}

.receta-print__conceptos-lista {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.receta-print__conceptos-concepto {
    display: flex;
    align-items: center;
    padding: var(--padding-lg) var(--padding-sm);
    gap: 5px;
    border-bottom: 1px solid var(--color-border);
    font-size: var(--font-size-sm);
    color: var(--color-text-light);
    margin-bottom: var(--padding-sm);
}

.receta-print__footer {
    width: 100%;
    padding: var(--padding-xl) var(--padding-md);
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.receta-print__botones {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    padding: var(--padding-md);
}