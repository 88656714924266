.receta-detalle {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: var(--padding-md);
}

.receta-detalle__top {
    width: 100%;
    padding: var(--padding-md);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.receta-detalle__top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.receta-detalle-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: var(--padding-md);
}

.receta-detalle-body__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.receta-detalle__productos-row {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
}

.receta-detalle__productos-row__head {
    display: flex;
    align-items: center;
    padding: var(--padding-sm) 2px;
}

.receta-detalle__productos-row__head span {
    width: 200px;
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-sm);
    color: var(--color-text-light);
}

.receta-detalle__productos-row__body {
    display: flex;
    gap: 20px;
}

.receta-detalle__productos-separador {
    width: 1px;
    background-color: var(--color-border);
    border-radius: var(--border-radius-md);
}