
.lista-medicamentos {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
}

.lista-medicamentos .lista-medicamentos__separacion {
    width: 1px;
    height: 215px;
    border-left: 1px solid var(--color-border);
}

.lista-medicamentos .lista-medicamentos__seccion {
    height: 215px;
    display: grid;
    gap: 8px;
}

.lista-medicamentos__seccion .lista-medicamentos__seccion__titulo {
    font-size: 12px;
    font-weight: var(--font-weight-regular);
    color: var(--color-border);
}

.lista-medicamentos__seccion .lista-medicamentos__seccion__titulo--invisible {
    visibility: hidden;
}

.lista-medicamentos__seccion .lista-medicamentos__seccion__lista {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
}