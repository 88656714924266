.card-producto-tabla {
    min-width: 320px;
    width: 100%;
    min-height: 136px;
    max-height: 136px;
    background: var(--color-card);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-border);
    padding: var(--padding-2sm);
    display: flex;
    gap: 5px;
    margin: auto;
    cursor: var(--cursor);
    transition: all var(--animation-duration) var(--animation-timing-function);
}

.card-producto-tabla:hover {
    transition: all var(--animation-duration) var(--animation-timing-function);
    transform: scale(1.02);
}

.card-producto-tabla__foto {
    min-width: 120px;
    min-height: 120px;
    border-radius: var(--border-radius-md);
    width: 120px;
    height: 120px;
    object-position: center;
    object-fit: cover;
    flex-grow: 2;
    flex-shrink: 1;
}

.card-producto-tabla__informacion {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--padding-sm);
    flex-grow: 1;
    flex-shrink: 2;
    overflow-y: auto;
}

.card-producto-tabla__informacion::-webkit-scrollbar {
    width: 3px;
}

.card-producto-tabla__informacion::-webkit-scrollbar-thumb {
    background-color: var(--color-background-elements-active);
    border-radius: var(--border-radius-md);
}

.card-producto-tabla__informacion__titulo {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
}

.card-producto-tabla__informacion__extra {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-regular);
}

.card-producto-tabla__informacion__extra .informacion__extra__subtile {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    color: var(--color-text-light);
}

.card-producto-tabla__informacion__extra span {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
}