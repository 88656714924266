.metodo-pago__efectivo {
    width: 100%;
    height: 100%;
    padding: var(--padding-md);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    outline: none;
}

.pago-efectivo__cantidad-box {
    width: 100%;
    padding: var(--padding-sm);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.pago-efectivo__captura {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.pago-efectivo__cantidad-input {
    flex-basis: 70%;
}

.pago-efectivo__cantidad-cambio {
    flex-basis: 30%;
    display: flex;
    gap: 5px;
    flex-direction: column;
    color: var(--color-text);
}

.pago-efectivo__cantidad-cambio__text {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
}

.pago-efectivo__montos {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 3px;
    flex-wrap: wrap;
}

.pago-efectivo__panel-numerico {
    width: 100%;
    padding: var(--padding-md);
}

.panel-numerico {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: var(--padding-md);
}

.panel-numerico__num-button {
    background-color: var(--color-background-input);
    border-radius: var(--border-radius-lg);
    border: 1px solid transparent;
    padding: var(--padding-lg);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    cursor: pointer;
    transition: var(--transition-duration) var(--transition-timing-function);
}

.panel-numerico__num-button--back {
    /* grid-column: span 2; */
    background-color: var(--color-negativo);
    color: var(--color-negativo-text);
}

.panel-numerico__num-button:hover {
    border-color: var(--color-primario);
}

.panel-numerico__num-button--animate {
    animation: border-flash 0.4s ease forwards;
}

.panel-numerico__num-button--animate:hover {
    border: 1px solid var(--color-primario);
}

@keyframes border-flash {
    0% {
        border-color: var(--color-primario);
    }

    100% {
        border-color: transparent;
    }
}

.pago-efectivo__footer {
    border-top: 1px solid var(--color-border);
    width: 100%;
    padding: var(--padding-md);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.pago-efectivo__footer-button {
    width: 100%;
    flex: 1
}