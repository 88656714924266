.metodo-pago {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.metodo-pago__opciones {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: var(--padding-md);
}

.metodo-pago__metodo {
    width: 100%;
    min-width: 150px;
    min-height: 80px;
    padding: var(--padding-lg);
    display: grid;
    place-content: center;
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-border);
    cursor: pointer;
    transition: var(--transition-duration) var(--transition-timing-function);
}

.metodo-pago__metodo-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.metodo-pago__metodo-icono {
    color: var(--color-text-light);
    font-size: var(--font-size-md);
    transition: var(--transition-duration) var(--transition-timing-function);
}

.metodo-pago__metodo-text {
    color: var(--color-text-light);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    transition: var(--transition-duration) var(--transition-timing-function);
}

.metodo-pago__metodo:hover {
    background-color: var(--color-background-opacity);
    border-color: var(--color-background-elements-hover);
}

.metodo-pago__metodo:hover .metodo-pago__metodo-icono,
.metodo-pago__metodo:hover .metodo-pago__metodo-text {
    color: var(--color-background-elements-hover);
}

.metodo-pago__metodo--active {
    background-color: var(--color-background-opacity);
    border-color: var(--color-background-elements-hover);
}

.metodo-pago__metodo--active .metodo-pago__metodo-icono,
.metodo-pago__metodo--active .metodo-pago__metodo-text {
    color: var(--color-background-elements-hover);
}

.metodo-pago__content {
    width: 100%;
    height: 100%;
}