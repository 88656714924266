.movimiento-tipo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.movimiento-tipo__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    padding: var(--padding-lg);
    min-height: 50px;
    border-bottom: 1px solid var(--color-background-elements);
    flex: 0 0 auto;
}

.movimiento-tipo__body {
    padding: var(--padding-lg);
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.movimiento-tipo__lista {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.movimiento-tipo__lista-item {
    width: 100%;
    padding: var(--padding-xl);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-background-elements-hover);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.movimiento-tipo__lista-item__icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-xl);
    color: var(--color-background-elements-hover);
}

.movimiento-tipo__lista-item__text {
    width: 100%;
    font-size: var(--font-size-xl);
    text-align: center;
    color: var(--color-background-elements-hover);
    font-weight: var(--font-weight-bold);
}