.seccion-principal {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.seccion-principal__header {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.seccion-principal__body {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.seccion-principal .seccion-principal__input-principal {
    width: 100%;
}

.seccion-principal .seccion-principal__input-secundario {
    width: 100%;
}

.seccion-principal .seccion-principal__input {
    display: flex;
    gap: 10px;
    /* width: 20%; */
}