:root {
    --max-height-tabla-productos: 700px
}

.contenedor-tabla-productos {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--padding-md);
}

.contenedor-tabla-productos__tabla {
    width: 100%;
    max-width: 1250px;
    height: 100%;
    padding: var(--padding-md);
    display: flex;
    flex-direction: column;
    gap: var(--padding-md);

}

.contenedor-tabla-productos__tabla__header {
    width: 100%;
    display: flex;
    gap: var(--padding-lg);
    align-items: center;
}

.contenedor-tabla-productos__tabla__grid {
    width: 100%;
    max-height: var(--max-height-tabla-productos);
    display: grid;
    gap: var(--padding-md);
    grid-template-columns: repeat(auto-fit, minmax(320px, auto));
    overflow-y: auto;
    overflow-x: hidden;
    padding: var(--padding-sm);
}

.contenedor-tabla-productos__tabla__grid::-webkit-scrollbar {
    width: 3px;
}

.contenedor-tabla-productos__tabla__grid::-webkit-scrollbar-thumb {
    background-color: var(--color-background-elements-active);
    border-radius: var(--border-radius-md);
}