
.card-info-archivo {
    width: 136px;
    min-height: 102px;
    height: fit-content; 
    padding: 16px;
    border-radius: var(--border-radius-xl);
    border: 1px solid var(--color-primario);
    background: var(--color-background-transparent);
    position: relative;
    color: var(--color-primario);
    display: grid;
    gap: 8px;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.card-info-archivo .card-info-archivo__icono-cerrar {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.card-info-archivo .card-info-archivo__icono-cerrar:hover {
    transform: scale(1.3);
    transition: all 0.2s ease-in-out;
}

.card-info-archivo .card-info-archivo__titulo {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: inherit;
    text-align: center;
}

.card-info-archivo .card-info-archivo__icono {
    font-size: 22px;
    text-align: center;
}

.card-info-archivo .card-info-archivo__nombre {
    width: fit-content;
    max-width: 70%;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-text);
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.card-info-archivo .card-info-archivo__extension {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-text);
    text-align: left;
}