.lista-ventas {
    width: 100%;
    grid-area: lista-ventas;
}

.lista-ventas__box {
    display: flex;
    align-items: center;
    padding: var(--padding-md);
    gap: 10px;
}

.lista-ventas__botones {
    display: flex;
    gap: 10px;
}

.list-ventas__boton {
    width: 100%;
    height: 100%;
    max-width: calc(var(--size-xxxxl) + 30px);
    min-height: calc(var(--size-xxxxl) + 10px);
    padding: var(--padding-lg);
    background-color: var(--color-background-elements-active);
    color: var(--color-text-primario-hover);
    cursor: pointer;
    border-radius: var(--border-radius-md);
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 0 0 auto;
}

.lista-ventas__boton-icon {
    display: grid;
    place-content: center;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
}

.lista-ventas__slide {
    display: flex;
    gap: 10px;
    flex: 1;
    position: relative;
}

.lista-ventas__slide-left {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 100%;
    color: var(--color-text);
    visibility: hidden;
    /* background-color: var(--color-background-opacity); */
    border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
    color: var(--color-text-primario);
    cursor: pointer;
    opacity: 0;
    transition: var(--transition-duration) var(--transition-timing-function);
    z-index: var(--z-index-1);
}

.lista-ventas__slide-right {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
    visibility: hidden;
    /* background-color: var(--color-background-opacity); */
    color: var(--color-text-primario);
    cursor: pointer;
    opacity: 0;
    transition: var(--transition-duration) var(--transition-timing-function);
    z-index: var(--z-index-1);
}

.lista-ventas__slide:hover .lista-ventas__slide-left,
.lista-ventas__slide:hover .lista-ventas__slide-right {
    background-color: #afafaf54;
    opacity: 1;
    visibility: visible;
}

.lista-ventas__items {
    width: 100%;
    overflow: hidden;
}

.list-ventas__items-box {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: var(--padding-sm);
    transition: var(--transition-duration) var(--transition-timing-function);
}

.lista-ventas__item-card {
    width: 100%;
    min-width: calc(var(--size-xxxxl) + 80px);
    min-height: calc(var(--size-xxxxl) + 10px);
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: var(--padding-sm);
    background-color: var(--color-card);
    border-radius: var(--border-radius-md);
    box-shadow: var(--box-shadow-light);
}

.lista-ventas__item-card__header {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
}

.lista-ventas__item-card__header-titulo {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-light);
}

.lista-ventas__item-card__header-subtitulo {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
}

.lista-ventas__item-card__body {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
}

.lista-ventas__item-card__body-info {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-regular);
    color: var(--color-text-light);
}

.lista-ventas__item-card__footer {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
}

.lista-ventas__item-card__estatus {
    padding: var(--padding-xs) var(--padding-sm);
    border-radius: var(--border-radius-sm);
}

.lista-ventas__item-card__estatus-text {
    font-size: var(--font-size-2xs);
    font-weight: var(--font-weight-bold);
}

.lista-ventas__item-card__estatus--pendiente {
    background-color: var(--color-pendiente);
    color: var(--color-pendiente-text);
}

.lista-ventas__item-card__estatus--activo {
    background-color: var(--color-activo);
    color: var(--color-activo-text);
}

.lista-ventas__item-card__estatus--cancelado {
    background-color: var(--color-negativo);
    color: var(--color-negativo-text);
}

.lista-ventas__item-card__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: var(--padding-xs) var(--padding-sm);
    cursor: pointer;
}

.lista-ventas__item-card__button-text {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
    color: var(--color-text-primario);
}

.lista-ventas__item-card__button-icon {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
    color: var(--color-text-primario);
}