.lista-productos {
    grid-area: lista-productos;
    padding: var(--padding-md);
    padding-top: 0;
    overflow-y: auto;
    height: 100%;
}

.lista-productos__header {
    display: flex;
    flex-direction: column;
    /* border-bottom: 1px solid #ccc; */
}

.lista-productos__filtros {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: var(--padding-md);
    border-top: 1px solid #ccc;
}

.lista-productos__filtros-first {
    display: flex;
    gap: 5px;
    color: var(--color-text-primario);
    font-weight: var(--font-weight-bold);
}

.lista-productos__body {
    display: flex;
    padding: var(--padding-md);
}

.lista-productos__body-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(min(250px, 100%), 1fr));
    grid-gap: 10px;
    height: 100%;
    transition: var(--transition-duration) var(--transition-timing-function);
}

.lista-productos__producto {
    width: 100%;
    padding: var(--padding-2sm);
    background-color: var(--color-card);
    border-radius: var(--border-radius-md);
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: var(--transition-duration) var(--transition-timing-function);
}

.lista-productos__producto-detalle {
    width: 100%;
    display: flex;
    gap: 5px;
}

.lista-productos__producto-detalle__imagen {
    width: 60px;
    height: 60px;
    border-radius: var(--border-radius-md);
    background-color: var(--color-background-elements);
}

.lista-productos__producto-detalle__info {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.lista-productos__producto-detalle__titulo {
    font-size: var(--font-size-sm);
    font-weight: bold;
    color: var(--color-text);
}

.lista-productos__producto-detalle__detalle {
    font-size: var(--font-size-xs);
    color: var(--color-text-light);
}

.lista-productos__producto-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lista-productos__producto-precio {
    display: flex;
    align-items: center;
    gap: 5px;
}

.lista-productos__producto-precio__text {
    font-size: var(--font-size-md);
    font-weight: bold;
    color: var(--color-text);
}

.lista-productos__producto-precio__detalle {
    font-size: var(--font-size-xs);
    color: var(--color-text-light);
}