.contenedor-checkbox-categoria {
    width: 100%;
    height: fit-content;
    cursor: var(--cursor);
    transition: var(--transition-property) var(--transition-duration) var(--transition-timing-function); 
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--padding-sm);
}

.checkbox-categoria {
    width: 16px !important;
    height: 16px !important;
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--color-border);
    padding: var(--padding-xs);
    transition: inherit;
}

.checkbox-categoria__seleccion {
    width: 100%;
    height: 100%;
    background: var(--color-primario);
    border-radius: inherit;
    opacity: 0;
    transition: inherit;
}

.checkbox-categoria__seleccion--visible {
    opacity: 1;
}

.checkbox-categoria__texto {
    font-family: var(--font);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.checkbox-categoria__texto--sm {
    font-family: var(--font);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
}

.contenedor-checkbox-categoria:hover .checkbox-categoria__seleccion {
    opacity: 1;
    transition: inherit;    
}

.contenedor-checkbox-categoria:hover .checkbox-categoria__texto {
    color: var(--color-primario);
}