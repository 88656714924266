.venta-proceso {
    width: 100%;
    grid-area: venta-proceso;
    position: absolute;
    right: 0;
    height: calc(100vh - 65px);
}

.venta-proceso__box {
    width: 100%;
    height: 100%;
}

.venta-proceso__card {
    width: 100%;
    height: 100%;
    background-color: var(--color-card);
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--color-background-elements);
}

.venta-proceso__header {
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
    padding: var(--padding-md);
    margin-bottom: 5px;
    flex: 0 0 auto;
}


.venta-proceso__header-back {
    flex: 0 0 auto;
}

.venta-proceso__header-titulo {
    width: 100%;
    flex: 1;
    text-align: center;
    font-size: var(--font-size-xl);
    color: var(--color-text);
    font-weight: var(--font-weight-bold);
}

.venta-proceso__content {
    width: 100%;
    height: 100%;
    flex: 1;
}