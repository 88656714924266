
.lista-archivos {
    width: 100%;
    height: fit-content;
    padding: var(--padding-md);
    border-radius: var(--border-radius-md);
    display: grid;
    gap: 10px;
    background: var(--color-card);
    box-shadow: var(--box-shadow-light);
}

.lista-archivos .lista-archivos__contenido-titulo {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.lista-archivos__contenido-titulo .lista-archivos__contenido-titulo__icono {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: var(--border-radius-sm);
}

.lista-archivos__contenido-titulo .lista-archivos__contenido-titulo__icono::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
}

.lista-archivos__contenido-titulo__icono i {
    width: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
}

.lista-archivos__contenido-titulo .card__contenido-titulo__titulo {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
}