
.button-agregar-archivo {
    width: 136px;
    min-height: 102px;
    height: fit-content;
    padding: 16px;
    display: grid;
    align-items: center;
    text-align: center;
    gap: 8px;
    border-radius: var(--border-radius-xl);
    border: 1px solid var(--color-primario);
    color: var(--color-primario);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.button-agregar-archivo:hover {
    background: var(--color-background-transparent);
    transition: all .2s ease-in-out;
}

.button-agregar-archivo i {
    font-size: 20px;
}

.button-agregar-archivo .button-agregar-archivo__texto {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
}