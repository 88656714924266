
.card-producto {
    width: 100%;
    max-width: 160px;
    min-height: 190px;
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow-light);
    background: var(--color-card);
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-primario);
}

.card-producto--inactivo {
    border: 1px solid var(--color-border);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.card-producto--inactivo:hover {
    transform: scale(1.03);
    transition: all 0.2s ease-in-out;
}

.card-producto--inactivo--sin-editar {
    cursor: initial;
    transform: none;
}

.card-producto .card-producto__foto {
    width: 100%;
    height: 100px;
    object-position: center;
    object-fit: cover;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.card-producto .card-producto__informacion {
    width: 100%;
    height: 90px;
    background: var(--color-primario);
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    padding: var(--padding-md);
    color: var(--color-card);
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
}

.card-producto--inactivo .card-producto__informacion {
    border-top: 1px solid var(--color-border);
    background: var(--color-card);
    color: var(--color-text);
    gap: 3px;
}

.card-producto__informacion .card-producto__informacion__precio {
    width: inherit;
    color: inherit;
    text-align: inherit;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-lg);
}

.card-producto__informacion .card-producto__informacion__nombre {
    width: inherit;
    color: inherit;
    text-align: inherit;
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-md);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.card-producto__informacion .card-producto__informacion__ineventario {
    width: inherit;
    color: inherit;
    text-align: inherit;
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-xs);
}